import React from "react";
import { useMediaQuery } from "react-responsive";

import ProductDesktop from "./ProductDesktop";
import ProductMobile from "./ProductMobile";

const Product = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 1224 });
  return isMobile ? (
    <ProductMobile {...props} />
  ) : (
    <ProductDesktop {...props} />
  );
};

export default Product;
