import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import Col from "antd/lib/col";
import Collapse from "antd/lib/collapse";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import React from "react";
import { useTranslation } from "react-i18next";

import SiteMetadata from "../SiteMetadata";
import { AddToCart, AddToSaved } from "./Actions";
import styles from "./product.module.scss";
import ProductImages from "./ProductImages";
import ProductInfo from "./ProductInfo";
import ProductPrice from "./ProductPrice";
import ProductSKUs from "./ProductSKUs";
import SelectQuantity from "./SelectQuantity";

const { Text, Title } = Typography;
const { Panel } = Collapse;

const ProductMobile = ({
  product,
  quantity,
  setQuantity,
  lineItem,
  sku,
  setSku,
}) => {
  const { title, description, images } = product;

  const { t } = useTranslation();

  if (!product) return null;

  return (
    <>
      <SiteMetadata
        productTitle={title || "Shop Products"}
        productDescription={description || `Your #1 shipping destination!`}
      />

      <Space
        direction="vertical"
        style={{ width: "100%" }}
        className={styles.container}
      >
        <ProductImages images={images} />
        <Tag
          style={{
            border: "none",
            background: "#57868f",
            position: "absolute",
            top: 30,
            left: 20,
            padding: "5px 10px",
          }}
        >
          <Text style={{ color: "white" }}>
            {product?.categories[1]?.title}
          </Text>
        </Tag>
        <Row justify="center">
          <Col span={24}>
            <Row justify="space-between">
              <Space direction="vertical" style={{ width: "75%" }} size={15}>
                <Title level={4} style={{ marginBottom: 0 }}>
                  {title}
                </Title>
              </Space>

              <AddToSaved className={styles.actionButton} product={product} />
            </Row>

            <Space direction="vertical" style={{ width: "100%" }} size={10}>
              <Collapse
                className={styles.description}
                bordered="false"
                expandIconPosition="right"
                defaultActiveKey={0}
                expandIcon={({ isActive }) =>
                  !isActive ? (
                    <PlusCircleOutlined style={{ fontSize: 16 }} />
                  ) : (
                    <MinusCircleOutlined style={{ fontSize: 16 }} />
                  )
                }
              >
                <Panel key={0} header={<Text>{t("product.description")}</Text>}>
                  <ProductInfo product={product} />
                </Panel>
              </Collapse>
              <Space direction="vertical" style={{ width: "100%" }} size={40}>
                <ProductSKUs product={product} sku={sku} setSku={setSku} />
                <SelectQuantity quantity={quantity} setQuantity={setQuantity} />
                <Title level={3} style={{ marginBottom: 0 }}>
                  <ProductPrice
                    lineItem={lineItem}
                    product={product}
                    quantity={quantity}
                  />
                </Title>
              </Space>
              <AddToCart lineItem={lineItem} />
            </Space>
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default ProductMobile;
