import Card from "antd/lib/card";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import React from "react";

import SiteMetadata from "../SiteMetadata";
import { AddToCart, AddToSaved } from "./Actions";
import styles from "./product.module.scss";
import ProductImages from "./ProductImages";
import ProductInfo from "./ProductInfo";
import ProductNav from "./ProductNav";
import ProductPrice from "./ProductPrice";
import ProductSKUs from "./ProductSKUs";
import SelectQuantity from "./SelectQuantity";

const { Title } = Typography;

const ProductDesktop = ({
  product,
  quantity,
  setQuantity,
  lineItem,
  sku,
  setSku,
}) => {
  const { title, description, categories, images } = product;
  if (!product) return null;
  return (
    <Space
      direction="vertical"
      style={{ width: "100%" }}
      className={styles.container}
    >
      <SiteMetadata
        productTitle={title || "Shop Products"}
        productDescription={description || `Your #1 shipping destination!`}
      />
      <ProductNav categories={categories} />
      <Divider />
      <Row justify="center" gutter={25}>
        <Col md={10}>
          <ProductImages images={images} />
        </Col>
        <Col md={14}>
          <Card
            title={
              <Title style={{ textAlign: "left", marginBottom: 0 }} level={3}>
                {title}
              </Title>
            }
            extra={<AddToSaved product={product} />}
            actions={[<AddToCart lineItem={lineItem} />]}
            bordered={false}
          >
            <Title level={3} style={{ textAlign: "left" }}>
              <ProductPrice
                product={product}
                lineItem={lineItem}
                quantity={quantity}
              />
            </Title>
            <ProductInfo product={product} />
            <Divider />
            <ProductSKUs product={product} sku={sku} setSku={setSku} />
            <Divider />
            <SelectQuantity quantity={quantity} setQuantity={setQuantity} />
          </Card>
        </Col>
      </Row>
    </Space>
  );
};

export default ProductDesktop;
