import Typography from "antd/lib/typography";
import React from "react";
import ReactMarkdown from "react-markdown";

const { Paragraph } = Typography;

const ProductInfo = ({ product }) => {
  const { description } = product;
  return (
    <Paragraph>
      <ReactMarkdown source={description} />
    </Paragraph>
  );
};

export default ProductInfo;
