import { max, min } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  selectCurrentExchangeRates,
  selectPreferredCurrency,
} from "../../state/app";
import { formatCurrency } from "../../utils/helpers";

const ProductPrice = ({ lineItem, product, quantity }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const rates = useSelector(selectCurrentExchangeRates);
  const preferredCurrency = useSelector(selectPreferredCurrency);

  const minPrice = formatCurrency(
    (min(product.prices) / rates[product.currency]) * quantity,
    preferredCurrency,
    language
  );
  const maxPrice = formatCurrency(
    (max(product.prices) / rates[product.currency]) * quantity,
    preferredCurrency,
    language
  );

  const priceRange =
    minPrice === maxPrice ? minPrice : [minPrice, maxPrice].join(" - ");

  return (
    <>
      {lineItem
        ? formatCurrency(
            (lineItem.price / rates[lineItem.currency]) * quantity,
            preferredCurrency,
            language
          )
        : priceRange}
    </>
  );
};

export default ProductPrice;
