import { graphql } from "gatsby";
import { find } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Product from "../components/Product";

export default ({ data }) => {
  const product = useProductWithLocale(data);

  const [quantity, setQuantity] = useState(1);
  const [sku, setSku] = useState();

  if (!product) return null;

  const {
    id: productId,
    description,
    title,
    seller,
    platform,
    sellerLink,

    slug,
    images,
  } = product;

  const lineItem = sku
    ? {
        productId,
        slug,
        description,
        quantity,
        images,

        seller,
        platform,
        sellerLink,

        name: title,

        skuId: sku.id,
        price: sku.price,
        variants: sku.variants,
        currency: sku.currency,
      }
    : undefined;

  return (
    <Product
      product={product}
      quantity={quantity}
      setQuantity={(v) => setQuantity(v)}
      sku={sku}
      setSku={setSku}
      lineItem={lineItem}
    />
  );
};

const useProductWithLocale = (data) => {
  const {
    i18n: { language },
  } = useTranslation();

  const productData = data.allContentfulProduct.edges.map((product) => {
    const {
      node: {
        id,
        title,
        slug,
        node_locale,
        description,
        images,
        seller,
        website,
        platform,
        skus,
        categories,
      },
    } = product;

    const filteredSkus = skus.map((sku) => {
      const { price, props, currency, contentfulid } = sku;
      return {
        currency,
        variants: props,
        id: contentfulid,
        price: price * 100,
      };
    });

    const urls =
      images
        .filter((image) => image.file && image.file.url)
        .map((image) => image.file.url) || [];

    const prices = filteredSkus.map((sku) => sku.price);

    return {
      id,
      title,
      slug,
      seller,
      categories,
      node_locale,

      description: description?.description,
      currency: filteredSkus[0].currency,
      platform: platform || `Mmucho`,

      sellerLink: website,
      skus: filteredSkus,
      prices: prices,
      images: urls,
    };
  });

  const product = find(
    productData,
    (product) => product.node_locale === language
  );

  const fallback = find(productData, (product) => product.node_locale === "en");
  return { ...product, slug: fallback.slug, images: fallback.images };
};

export const query = graphql`
  query($id: String!) {
    allContentfulProduct(filter: { contentful_id: { eq: $id } }) {
      edges {
        node {
          id
          title
          slug
          node_locale
          contentful_id
          seller
          # FIXME: gatsby requires at least one value for each field
          # For the Mmucho Wet Market phase, 'website' is not used
          # website
          platform
          description {
            description
          }
          images {
            file {
              url
            }
          }
          skus {
            price
            props
            currency
            id
            contentfulid
          }
          categories {
            hierarchyLevel
            title
            slug
          }
        }
      }
    }
  }
`;
