import Row from "antd/lib/row";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Carousel } from "react-responsive-carousel";

import styles from "./product.module.scss";

const ProductImages = ({ images }) => {
  const isMobile = useMediaQuery({ maxWidth: 1224 });

  return (
    <Row justify="center">
      {isMobile ? (
        <div className={styles.wrapper}>
          <Carousel
            showIndicators={true}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
          >
            {images.map((image) => {
              return (
                <img
                  className={styles.image}
                  key={image}
                  src={image}
                  alt={image}
                />
              );
            })}
          </Carousel>
        </div>
      ) : (
        <Carousel dynamicHeight={true}>
          {images.map((image) => {
            return <img key={image} src={image} alt={image} />;
          })}
        </Carousel>
      )}
    </Row>
  );
};

export default ProductImages;
