import { Breadcrumb, Row } from "antd";
import { Link } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

import { getCategorySlug } from "../URLSync";

const buildNestedCategoryPath = (categories, index) => {
  const hierarchicalCategory = categories
    .slice(0, index + 1)
    .map((category) => category.title)
    .join(" > ");

  return getCategorySlug(hierarchicalCategory);
};

const ProductNav = ({ categories }) => {
  const { t } = useTranslation();
  return (
    <Row justify="center">
      <Breadcrumb>
        <Breadcrumb.Item key="home">
          <Link to="/app/search">{t("product.allCategories")}</Link>
        </Breadcrumb.Item>
        {categories.map((category, index) => {
          return (
            <Breadcrumb.Item key={category.slug}>
              <Link
                to={`/app/search/${buildNestedCategoryPath(
                  categories,
                  index
                )}/`}
              >
                {category.title}
              </Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </Row>
  );
};

export default ProductNav;
