import Select from "antd/lib/select";
import Space from "antd/lib/space";
import { find, isEqual, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// import { useMediaQuery } from "react-responsive";

// import { List, Picker } from "antd-mobile";

const { Option } = Select;

const getSKUPropVariants = (skus) => {
  return skus.reduce((result, sku) => {
    (sku.variants || []).forEach((pv) => {
      const [p, v] = pv.replace("：", ":").split(":");
      // Allows chinese colon to split descriptors
      if (!result[p]) result[p] = [];
      if (!result[p].includes(v)) result[p].push(v);
    });
    return result;
  }, {});
};

const convertVariantsToMap = (variants) => {
  return (variants || []).reduce((result, pv) => {
    const [p, v] = pv.replace("：", ":").split(":");
    // Allows chinese colon to split descriptors
    result[p] = v;
    return result;
  }, {});
};

const ProductSKUs = ({ product, sku, setSku }) => {
  // const isMobile = useMediaQuery({ maxWidth: 1224 });
  const { t } = useTranslation();

  const [variants, setVariants] = useState();

  // const [mobileVariants, setMobileVariants] = useState();

  const handleSkuPropChange = (propertyName) => (val) => {
    // setMobileVariants(variants);
    setVariants((state) =>
      val
        ? {
            ...state,
            [propertyName]: val,
          }
        : undefined
    );
  };

  useEffect(() => {
    const match = find(product.skus, (sku) =>
      isEqual(variants, convertVariantsToMap(sku.variants))
    );

    if (!match) return;
    if (!sku || sku.id !== match.id) setSku(match);
  }, [product.skus, variants, setSku, sku]);

  const allOptionsByLabel = getSKUPropVariants(product.skus);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {map(allOptionsByLabel, (variants, propertyName) => {
        return (
          <Select
            key={propertyName}
            defaultValue={`${t("product.select")} ${propertyName}
              `}
            size="large"
            onChange={handleSkuPropChange(propertyName)}
            style={{ width: "100%" }}
          >
            {!sku && (
              <Option
                key="default"
                value={`${t("product.select")} ${propertyName}
                  `}
              >
                Select {propertyName}
              </Option>
            )}
            {variants.map((variant) => {
              return (
                <Option key={variant} value={variant}>
                  {variant}
                </Option>
              );
            })}
          </Select>
        );
      })}
    </Space>
  );
};

export default ProductSKUs;
