import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import Button from "antd/lib/button";
import InputNumber from "antd/lib/input-number";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const { Text } = Typography;

const SelectQuantity = ({ quantity, setQuantity }) => {
  const isMobile = useMediaQuery({ maxWidth: 1224 });

  const { t } = useTranslation();

  if (isMobile)
    return (
      <Row justify="center" align="middle">
        <Button
          type="link"
          disabled={quantity < 2}
          onClick={() => setQuantity(quantity - 1)}
        >
          <MinusCircleFilled style={{ fontSize: 24 }} />
        </Button>
        <InputNumber
          size="large"
          bordered={false}
          value={quantity}
          onChange={(value) => setQuantity(value)}
          min={1}
        />
        <Button type="link" onClick={() => setQuantity(quantity + 1)}>
          <PlusCircleFilled style={{ fontSize: 24 }} />
        </Button>
      </Row>
    );
  else
    return (
      <Space>
        <Text strong>{t("product.chooseQuantity")}</Text>
        <InputNumber
          value={quantity}
          onChange={(value) => setQuantity(value)}
          min={1}
        />
      </Space>
    );
};

export default SelectQuantity;
